import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Use local dev tools on remote files with SSHFS",
  "component": "none",
  "date": "2020-11-13T22:45:13.234Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I often have to work with virtual machines or remote hosts. To utilize my local development environment while doing so I configure SSH and SSHFS. Enabling the use of tools on the local host while working with files on the remote, with the security of ssh.`}</p>
    <p><strong parentName="p">{`SSHFS`}</strong>{` (SSH filesystem) enables the mounting of a remote directory over a normal ssh connection to the local filesystem. Prior to installing, make sure `}<a parentName="p" {...{
        "href": "/posts/ssh-ubuntu-virtualbox/post"
      }}>{`SSH is setup`}</a>{` with the appropriate keys.`}</p>
    <ol>
      <li parentName="ol">{`Download & Install `}<a parentName="li" {...{
          "href": "https://osxfuse.github.io/"
        }}>{`FUSE for macOS`}</a></li>
      <li parentName="ol">{`Download & Install `}<a parentName="li" {...{
          "href": "https://osxfuse.github.io/"
        }}>{`SSHFS`}</a></li>
    </ol>
    <h2 {...{
      "id": "mount-remote-filesystems",
      "style": {
        "position": "relative"
      }
    }}>{`Mount remote filesystems`}<a parentName="h2" {...{
        "href": "#mount-remote-filesystems",
        "aria-label": "mount remote filesystems permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The following command mounts the remote directory to a specified mount point.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "",
      "data-index": "0"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}>{`sshfs username@remotesystem:/remote/path/to/directory ~/mount/point`}</span></span></code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/tkolleh/dotfiles/blob/master/ws/usrBinScripts/executable_rmount.sh"
      }}><inlineCode parentName="a">{`rmount`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/tkolleh/dotfiles/blob/master/ws/usrBinScripts/executable_rumount.sh"
      }}><inlineCode parentName="a">{`rumount`}</inlineCode></a>{` bash scripts facilitate convenient mount and unmounts using `}<inlineCode parentName="p">{`sshfs`}</inlineCode>{`. To begin using the scripts.`}</p>
    <ol>
      <li parentName="ol">{`Create a folder named `}<strong parentName="li">{`mounts`}</strong>{` in your user home directory, `}<inlineCode parentName="li">{`~/mounts`}</inlineCode>{`.`}</li>
      <li parentName="ol">{`Download the scripts and make them executable, `}<inlineCode parentName="li">{`chmod u+x <path-to-script>`}</inlineCode>{`.`}</li>
    </ol>
    <p>{`The functions reference host named in the local `}<inlineCode parentName="p">{`~/.ssh/config`}</inlineCode>{` file, configured with a key pair (without a password). See `}<a parentName="p" {...{
        "href": "/posts/ssh-ubuntu-virtualbox/post"
      }}>{`earlier blog`}</a>{` post on the subject for reference.`}</p>
    <pre {...{
      "className": "grvsc-container dark-default-dark",
      "data-language": "c",
      "data-index": "1"
    }}><code parentName="pre" {...{
        "className": "grvsc-code"
      }}><span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`Host ml`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  HostName ec2-*.compute-`}</span><span parentName="span" {...{
              "className": "mtk14"
            }}>{`1.amazonaws.com`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  User ubuntu`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  PreferredAuthentications publickey`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  IdentitiesOnly yes`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  IdentityFile ~/.ssh/aws`}</span></span></span>{`
`}<span parentName="code" {...{
          "className": "grvsc-line"
        }}><span parentName="span" {...{
            "className": "grvsc-source"
          }}><span parentName="span" {...{
              "className": "mtk1"
            }}>{`  Port `}</span><span parentName="span" {...{
              "className": "mtk7"
            }}>{`22`}</span></span></span></code></pre>
    <p>{`Executing `}<inlineCode parentName="p">{`rmount ml`}</inlineCode>{` - will mount the entire filesystem to a local `}<inlineCode parentName="p">{`ml`}</inlineCode>{` (`}<inlineCode parentName="p">{`~/mounts/ml`}</inlineCode>{`) folder. A folder created by the function. The default “mounts” path, created earlier, can be changed by modifying the function. Executing `}<inlineCode parentName="p">{`rmount ml:/home/ec2-user`}</inlineCode>{` mounts the `}<inlineCode parentName="p">{`ec2-user`}</inlineCode>{` directory in `}<inlineCode parentName="p">{`~/mounts/ml/`}</inlineCode>{`. Running `}<inlineCode parentName="p">{`rumount ml`}</inlineCode>{`, removes the `}<inlineCode parentName="p">{`ml`}</inlineCode>{` folder and unmounts the remote filesystem. `}</p>
    <p>{`With a remote filesystem mounted locally, local development tools can be used without having to download each file or having to remember to sync files. Its a secure tunnel to your remote filesystem where files behave almost as if they’re local.`}</p>
    <br />
    <p>{`I’m publishing this as part of 100 Days To Offload. You can learn more by visiting `}<a parentName="p" {...{
        "href": "https://100daystooffload.com"
      }}>{`#100DaysToOffload`}</a>{`.`}</p>
    <br />
    <blockquote>
      <h2 parentName="blockquote" {...{
        "id": "references",
        "style": {
          "position": "relative"
        }
      }}>{`References`}<a parentName="h2" {...{
          "href": "#references",
          "aria-label": "references permalink",
          "className": "anchor after"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a></h2>
      <ul parentName="blockquote">
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://tools.ietf.org/html/rfc4251"
          }}>{`The Secure Shell Protocol`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://brettterpstra.com/2013/02/10/the-joy-of-sshfs/"
          }}>{`Inspiration`}</a></li>
      </ul>
    </blockquote>

    <style {...{
      "className": "grvsc-styles"
    }}>{`
  .grvsc-container {
    overflow: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    padding-top: 1rem;
    padding-top: var(--grvsc-padding-top, var(--grvsc-padding-v, 1rem));
    padding-bottom: 1rem;
    padding-bottom: var(--grvsc-padding-bottom, var(--grvsc-padding-v, 1rem));
    border-radius: 8px;
    border-radius: var(--grvsc-border-radius, 8px);
    font-feature-settings: normal;
    line-height: 1.4;
  }
  
  .grvsc-code {
    display: table;
  }
  
  .grvsc-line {
    display: table-row;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .grvsc-line > * {
    position: relative;
  }
  
  .grvsc-gutter-pad {
    display: table-cell;
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  .grvsc-gutter {
    display: table-cell;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter::before {
    content: attr(data-content);
  }
  
  .grvsc-source {
    display: table-cell;
    padding-left: 1.5rem;
    padding-left: var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem));
    padding-right: 1.5rem;
    padding-right: var(--grvsc-padding-right, var(--grvsc-padding-h, 1.5rem));
  }
  
  .grvsc-source:empty::after {
    content: ' ';
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
  
  .grvsc-gutter + .grvsc-source {
    padding-left: 0.75rem;
    padding-left: calc(var(--grvsc-padding-left, var(--grvsc-padding-h, 1.5rem)) / 2);
  }
  
  /* Line transformer styles */
  
  .grvsc-has-line-highlighting > .grvsc-code > .grvsc-line::before {
    content: ' ';
    position: absolute;
    width: 100%;
  }
  
  .grvsc-line-diff-add::before {
    background-color: var(--grvsc-line-diff-add-background-color, rgba(0, 255, 60, 0.2));
  }
  
  .grvsc-line-diff-del::before {
    background-color: var(--grvsc-line-diff-del-background-color, rgba(255, 0, 20, 0.2));
  }
  
  .grvsc-line-number {
    padding: 0 2px;
    text-align: right;
    opacity: 0.7;
  }
  
  .dark-default-dark {
    background-color: #1E1E1E;
    color: #D4D4D4;
  }
  .dark-default-dark .mtk1 { color: #D4D4D4; }
  .dark-default-dark .mtk14 { color: #F44747; }
  .dark-default-dark .mtk7 { color: #B5CEA8; }
  .dark-default-dark .grvsc-line-highlighted::before {
    background-color: var(--grvsc-line-highlighted-background-color, rgba(255, 255, 255, 0.1));
    box-shadow: inset var(--grvsc-line-highlighted-border-width, 4px) 0 0 0 var(--grvsc-line-highlighted-border-color, rgba(255, 255, 255, 0.5));
  }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      